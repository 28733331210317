<template>
    <div class="home" v-loading="loading">
        <!-- 头部 -->
        <!-- <largeHead :tabArrShow="tabArrShow"></largeHead> -->

        <!-- 全部展会 -->
        <div style="width: 100%; display: flex;align-items: center;justify-content: center;margin: 30px 0 ;">
            <img style="width: 160px;height: auto" src="@/assets/zhxq.png" alt="">
        </div>

        <!-- 列表 -->
        <div class="list" v-for="(item, index) in pavilionInformation" :key="index" >
            <div class="title">
                <div class="l">{{ item.title }}</div>
                <div class="r" @click="genDuoZH(item)">
                    <div class="text">更多</div>
                    <img src="@/assets/icon/gd.png" alt="">
                </div>
            </div>
            <div class="cont" v-for="(i, inde) in item.children" :key="inde">
                <div class="img">
                    <img :src="i.logo" alt="">
                </div>
                <div class="info">
                    <div class="name">{{ i.title }}</div>
                    <div class="userName">联系人：<span>{{ i.contact }}</span></div>
                    <div class="userName">联系电话：<span>{{ i.mobile }}</span></div>
                    <div class="text">
                        开展内容：{{ i.content }}
                    </div>
                </div>
                <div class="box_btn">
                    <div class="btn" @click="vrUrl(i.vrUrl)">点击进入VR展厅</div>
                </div>
            </div>
        </div>


        <!-- 分页 -->
        <paging :total="paging.total" @handleCurrentChange="handleCurrentChange" :page-size="paging.size"></paging>

        <!-- 底部 -->
        <!-- <bottomContent></bottomContent> -->
    </div>
</template>

<script>
import largeHead from "@/components/largeHead.vue";
import bottomContent from "@/components/bottomContent.vue";
import paging from "@/components/paging.vue";
import { getPavilionInformation, getPavilionSpecificInformation } from "@/utils/api/homeApi/index"
export default {
    name: 'exhibitionInfo',
    components: {
        largeHead,
        bottomContent,
        paging
    },
    data() {
        return {
            loading:true,
            // 头部激活菜单
            tabArrShow: 7,
            zhId: '',
            paging: {
                size: 2,
                current: 1,
                total: 0
            },
            pavilionInformation: [],
            shoppingListTotal: 0
        };
    },
    mounted() {
        this.zhId = this.$route.query.zhId
        this.getPavilion()
    },
    methods: {
        genDuoZH(i){
            console.log(i)
            this.$store.commit("scrollToTop")
            this.$router.push(`/exhibitionInfo2?zgId=${i.zgId}&title=${i.title}`)
        },
        async getPavilion() {
            const res = await getPavilionInformation({
                zhId: this.zhId,
                size: this.paging.size,
                current: this.paging.current
            })
            if (res.statusCode == 8201) {
                this.pavilionInformation = res.data.records
                this.paging.total = res.data.total
                this.pavilionInformation.map(async (d, i) => {
                    const ret = await getPavilionSpecificInformation({
                        zgId: d.zgId,
                        size: 2,
                        current: 1
                    })
                    if(ret.statusCode == 8201) {
                        this.$set(this.pavilionInformation[i], 'children', ret.data.records.slice(0, 2));
                        this.loading = false
                    }
                })
                
            }
            console.log(this.pavilionInformation)
        },
        handleCurrentChange(val){
            this.loading = true
            this.paging.current = val
            this.getPavilion()
        },
        vrUrl(url) {
            window.open(url, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>
.home {
    width: 100%;
    min-height: 100vh;
    background-color: #f5f5f5;


    // 列表

    >.list {
        width: 1200px;
        min-height: 568px;
        margin: 0 auto;

        >.title {
            width: 100%;
            height: 68px;
            margin: 0 auto;
            padding: 20px;
            box-sizing: border-box;
            background-color: #E3E4E5;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >.l {
                font-size: 20px;
                color: #333;
            }

            >.r {
                font-size: 14px;
                color: #999;
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: all .3s;

                >img {
                    width: 16px;
                    height: 16px;
                    margin-left: 5px;
                }
            }

            >.r :hover {
                color: #ff4242;
                transition: all .3s;
            }
        }

        >.cont {
            width: 100%;
            height: auto;
            padding: 20px;
            box-sizing: border-box;
            background-color: #fff;
            border: 1px;
            display: flex;
            margin-bottom: 20px;

            >.img {
                width: 270px;
                height: 126px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.info {
                width: 646px;
                height: 160px;
                margin-left: 30px;

                >.name {
                    font-size: 20px;
                    color: #333;
                    margin-bottom: 5px;
                }

                >.userName {
                    font-size: 14px;
                    color: #666;
                    margin-top: 3px;
                }

                >.text {
                    font-size: 12px;
                    color: #666;
                    margin-top: 12px;
                }
            }

            >.box_btn {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 60px;

                >.btn {
                    width: 116px;
                    height: 37px;
                    background: #FF5148;
                    border-radius: 33px 33px 33px 33px;
                    text-align: center;
                    line-height: 37px;
                    font-size: 12px;
                    color: #fff;
                    cursor: pointer;
                }
            }

        }
    }


    // 分页
    .paging {
        width: 1200px;
        height: 50px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 50px;
    }
}


// 分页样式
/deep/ .el-pagination .active {
    background-color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-pagination:hover .active:hover {
    color: #fff !important;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff;
    border: 1px solid #fff;
    transition: all .3s !important;
}

/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #ff4242;
    border: 1px solid #ff4242;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-right:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}

/deep/ .el-icon-arrow-left:hover {
    color: #ff4242 !important;
    transition: all .3s !important;
}
</style>